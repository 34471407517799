import React from "react"

import { Layout } from "@/layout"
import {
  Box,
  SEO,
  Width,
  CTA,
  Button,
  VideoPlayer,
  Heading,
  Text,
} from "@/components"
import localStorage from "@/utils/localStorage"

export default function Level() {
  const loadedOnce = localStorage.get("loadedOnce")
  if (!loadedOnce) {
    if (typeof window !== `undefined`) {
      window.location.reload()
    }
    localStorage.set("loadedOnce", true)
  }
  return (
    <Layout
      headerType="checkout"
      headerTheme="light"
      footerTheme="light"
      squeeze={true}
    >
      <SEO
        title="How to get Unstuck on Guitar"
        description="A Free Training from Sonora Guitar Intensive."
      />
      <Box
        bg="agate"
        height="100%"
        position="relative"
        zIndex="3"
        top="0"
        width="100%"
        display="flex"
        flexDirection="column"
        textAlign="center"
        pt={["7.5rem", null, null, "9.6rem"]}
        pb={["4.8rem", null, null, "0"]}
      >
        <Box width={["100%", null, null, 5 / 12]} textAlign="center" mx="auto">
          <Text variant="subhead" mt="4rem">
            FREE TRAINING
          </Text>

          <Heading
            level="1"
            mb="1rem"
            mt="1rem"
            px={["2rem", "2rem", "2rem", "2rem"]}
          >
            What is your skill level?
          </Heading>
          <Text mb="1rem" mt="1rem" px={["2rem", "2rem", "2rem", "2rem"]}>
            This will help us to get you the right training.
          </Text>
        </Box>
        <Width
          display="flex"
          flexDirection="column"
          pt={["2.2rem", null, null, "2.2rem"]}
          pb={["0", null, null, "6.9rem"]}
        >

          <Box mb={["1rem", null, null, "0"]}>
            <CTA to="/freetraining/training-beginner" mt="1rem">
              🌱 Beginner
            </CTA>
          </Box>
          <Box mb={["1rem", null, null, "0"]}>
            <CTA to="/freetraining/training-intermediate" mt="1rem">
              ⛰️ Intermediate
            </CTA>
          </Box>
          <Box mb={["1rem", null, null, "0"]}>
            <CTA to="/freetraining/training-advanced" mt="1rem">
              🤘 Advanced
            </CTA>
          </Box>
          <Box mb={["1rem", null, null, "0"]}>
            <CTA to="/freetraining/training-unsure" mt="1rem">
              🤔 I'm not sure
            </CTA>
          </Box>
        </Width>
      </Box>
    </Layout>
  )
}
